import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Likes from "../likes";
import Token_dropdown from "../dropdown/Token_dropdown";
import { useDispatch, useSelector } from "react-redux";
import { bidsModalShow, mintLeosModalShow } from "../../redux/counterSlice";
import { useAlert } from "react-alert";
import { useRouter } from "next/router";
import { useWallet } from "@demox-labs/aleo-wallet-adapter-react";



const CategoryItem = ({ tokens, collections, noFeature }) => {
  if (!tokens)
    tokens = [];
  const dispatch = useDispatch();
  const alert = useAlert();
  const router = useRouter();
  const { publicKey } = useWallet();
  return (
    <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
      {
        tokens.lenght === 0 && <div className="text-center">No items found</div>
      }
      {tokens.map((item) => {
        const itemLink = item.hex_id;
        return item.collection ? (
          <article key={item.hex_id}>
            <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
              <figure className="relative">
                <Link href={`/token/${itemLink}`}>
                  <a>
                    <img
                      src={item.metadata.image}
                      alt="item 5"
                      className="w-full h-[230px] rounded-[0.625rem] object-cover"
                    />
                  </a>
                </Link>

                <div className="absolute left-3 -bottom-3">
                  <div className="flex -space-x-2 hidden">
                    <Link href={`/collection/${item.collection.hex_id}`}>
                      <a>
                        <Tippy className="p-1" content={<span>[{item.collection.public_data.metadata.ticker}] {item.collection.public_data.metadata.name}</span>}>
                          <img
                            src={item.collection.public_data.metadata.icon_url}
                            alt="owner"
                            layout="fill"
                            className="dark:border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2 border-white"
                          />
                        </Tippy>
                      </a>
                    </Link>
                  </div>
                </div>
              </figure>
              <div className="mt-7 flex items-center justify-between">
                <Link href={`/token/${itemLink}`}>
                  <a>
                    <span className="font-display text-jacarta-700 text-base dark:text-white normal-case">
                      {item.metadata.name}
                    </span>
                  </a>
                </Link>

                {/* auction dropdown  */}
                <Token_dropdown collections={collections} token={item} classes="dark:hover:bg-jacarta-600 dropup hover:bg-jacarta-100 rounded-full " />
              </div>

              <span className="dark:text-jacarta-200 text-jacarta-400 mr-1 text-sm	hover:text-accent">
                <Link href={`/collection/${item.collection.hex_id}`}>
                  <a className="block" style={{
                    float: "left",
                    display: "flex",
                    alignItems: "center",
                  }}>
                    <img
                      src={item.collection.public_data.metadata.icon_url}
                      alt="owner"
                      layout="fill"
                      className="float-left block float-left dark:border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-0 border-white"
                      style={{
                        float: "left",
                        display: "inline-block",
                        marginRight: "3px",
                      }}
                    />
                    <span className="grid float-left">[{item.collection.public_data.metadata.ticker}] {item.collection.public_data.metadata.name}</span>
                  </a>
                </Link>
              </span>

              {!noFeature && (
                <div className="mt-8 flex items-center justify-between">
                  <button
                    className="text-accent font-display text-sm font-semibold block pl-[20px] pr-[20px] pt-[10px] pb-[10px] background-transparent hover:bg-accent hover:text-white border border-accent hover:border-transparent rounded-full"
                    onClick={() => {
                      if (item._record) {
                        alert.error("You can only list public tokens. Transfer to public first.");
                        router.push(`/token/${item.hex_id}/transfer?dest=public&to=self`)
                        return
                      }
                      dispatch(bidsModalShow({
                        token_id: item.hex_id,
                        royalty_fees: item.collection.public_data.royalty_fees,
                        is_update: false
                      }))
                    }}
                  >
                    Sell
                  </button>
                  <Link href={`/token/${itemLink}/transfer`}>
                    <a className="group flex items-center">
                      <svg className="icon icon-history group-hover:fill-accent dark:fill-jacarta-200 fill-jacarta-500 mr-1 mb-[3px] h-4 w-4">
                        <use xlinkHref="/icons.svg#icon-transfer"></use>
                      </svg>
                      <span className="group-hover:text-accent font-display dark:text-jacarta-200 text-sm font-semibold">
                        Transfer
                      </span>
                    </a>
                  </Link>
                </div>
              )}
            </div>
          </article>
        ) : (
          <article key={item.name + item.edition}>
            <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
              <figure className="relative">
                <img
                  src={item.metadata.image}
                  alt="item 5"
                  className="w-full h-[230px] rounded-[0.625rem] object-cover"
                />
              </figure>
              <div className="mt-7 flex items-center justify-between">
                <span className="font-display text-jacarta-700 text-base dark:text-white">
                  {item.name}
                </span>
              </div>
              <span className="dark:text-jacarta-200 text-jacarta-400 mr-1 text-sm">
                <span className="block" style={{
                  float: "left",
                  display: "flex",
                  alignItems: "center",
                }}>
                  <span className="grid float-left">Edition: {item.edition.slice(0, item.edition.length - 6)}</span>
                </span>
              </span>
              <div className="mt-8 flex items-center justify-between">
                <button
                  className="text-accent font-display text-sm font-semibold block pl-[20px] pr-[20px] pt-[10px] pb-[10px] background-transparent hover:bg-accent hover:text-white border border-accent hover:border-transparent rounded-full"
                  onClick={() => {
                    dispatch(mintLeosModalShow({
                      record: item._record,
                    }))
                  }}
                >
                  Import
                </button>
              </div>
            </div>
          </article>
        );
      })}
    </div>
  );
};

export default CategoryItem;
