import React, { useState } from "react";
import Link from "next/link";

import { useDispatch, useSelector } from "react-redux";
import { burnModalShow, proveModalShow } from "../../redux/counterSlice";
import { useWallet } from "@demox-labs/aleo-wallet-adapter-react";
import { useAlert } from "react-alert";
import { useRouter } from "next/router";
import { transfer_private } from "lib/client/aleo";
import {
  token_hex_to_token_id
} from 'lib/client/aleo';

async function burnPP(
  publicKey,
  requestRecords,
  requestTransaction,
  token_record,
  router,
  alert,
  loading,
  setLoading
) {
  if (loading || !token_record || !publicKey) return;
  setLoading(true);
  try {
    const response = await fetch(
      `/api/v0/leos/burn/${publicKey}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      }
    );
    const data = await response.json();

    if (data.error)
      throw new Error(data.error);

    const res = await transfer_private(
      publicKey, requestRecords, requestTransaction, token_record, data.address,
    );

    alert.success("Transaction in progress.");
    router.push("/transaction/status/leo/" + res);

  } catch (e) {
    const err_str = e?.error?.message || e?.message || e + "";
    alert.error(err_str);
    console.log(err_str);
  }
  setLoading(false);
}




const Token_dropdown = ({ classes, token, collections }) => {
  const [dropdownShow, setDropdownShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const is_private = token._record !== undefined;
  const dispatch = useDispatch();
  const router = useRouter();
  const alert = useAlert();
  const { publicKey, requestRecords, requestTransaction } = useWallet();


  const collection_owned = (
    collections.filter((collection) => token.collection.hex_id === collection.hex_id)
  )[0];


  const handleDropdown = () => {
    window.addEventListener("click", (w) => {
      if (w.target.closest(".trending-dots")) {
        if (dropdownShow) {
          setDropdownShow(false);
        } else {
          setDropdownShow(true);
        }
      } else {
        setDropdownShow(false);
      }
    });
  };
  console.log("collection_owned", collection_owned);
  const token_owned = token.owner === publicKey;

  return (
    <>
      {((collection_owned || token_owned) && !(!token_owned && collection_owned && !collection_owned.data.updatable)) && (
        <div className="relative">
          <div className={classes}>
            <button
              onClick={handleDropdown}
              className="dropdown-toggle trending-dots inline-flex h-10 w-10 items-center justify-center text-sm show"
            >
              <svg
                width="16"
                height="4"
                viewBox="0 0 16 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="dark:fill-jacarta-200 fill-jacarta-500"
              >
                <circle cx="2" cy="2" r="2"></circle>
                <circle cx="8" cy="2" r="2"></circle>
                <circle cx="14" cy="2" r="2"></circle>
              </svg>
            </button>

            <div
              className={
                dropdownShow
                  ? "dropdown-menu-end dark:bg-jacarta-800 z-10 min-w-[200px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl show text-jacarta-500 absolute top-full right-0"
                  : "dropdown-menu-end dark:bg-jacarta-800 z-10 min-w-[200px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl hidden text-jacarta-500 absolute top-full right-0"
              }
            >
              {(!is_private || token.collection.public_data.publicizable) && token_owned && (<Link href={`/token/${token.hex_id}/transfer?dest=${is_private ? "public" : "private"}&to=self`}>
                <a>
                  <button className="dark:hover:bg-jacarta-600 font-display hover:bg-jacarta-100 block w-full rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white">
                    Make {is_private ? "public" : "private"}
                  </button>
                </a>
              </Link>)}
              {is_private && (
                <>
                  <button
                    onClick={() => dispatch(proveModalShow(
                      {
                        token: token._record,
                        type: "token_ownership"
                      }
                    ))}
                    className="dark:hover:bg-jacarta-600 font-display hover:bg-jacarta-100 block w-full rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white">
                    Prove token ownership
                  </button>
                  <button
                    onClick={() => dispatch(proveModalShow(
                      {
                        token: token._record,
                        type: "collection_holdership"
                      }
                    ))}
                    className="dark:hover:bg-jacarta-600 font-display hover:bg-jacarta-100 block w-full rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white">
                    Prove collection holdership
                  </button>
                  {token.collection.hex_id === process.env.NEXT_PUBLIC_PRIVACY_PRIDE_COLLECTION_ID && (
                    <button
                      onClick={() => burnPP(
                        publicKey,
                        requestRecords,
                        requestTransaction,
                        token,
                        router,
                        alert,
                        loading,
                        setLoading
                      )}
                      className="dark:hover:bg-jacarta-600 font-display hover:bg-jacarta-100 block w-full rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white">
                      Unwrap to original program
                    </button>
                  )}

                </>
              )}
              {collection_owned && (
                <>
                  {collection_owned.data.updatable && (
                    <>
                      <hr className="dark:bg-jacarta-600 bg-jacarta-100 my-2 mx-4 h-px border-0" />
                      <Link href={`/token/${token.hex_id}/edit`}>
                        <button className="dark:hover:bg-jacarta-600 font-display hover:bg-jacarta-100 block w-full rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white">
                          Update token metadata
                        </button>
                      </Link>
                      <button onClick={() => dispatch(burnModalShow(
                        {
                          token: is_private ? token._record : token_hex_to_token_id(token.hex_id),
                          collection: collection_owned._record,
                          is_private
                        }
                      ))} className="dark:hover:bg-jacarta-600 font-display hover:bg-jacarta-100 block w-full rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white">
                        Burn token definitively
                      </button>
                    </>
                  )}
                </>
              )}
            </div>

          </div>
        </div >
      )
      }
    </>
  );
};

export default Token_dropdown;
